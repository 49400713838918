import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import Moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from "./baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Modal } from "react-bootstrap";
const baseUrl = myConstList.baseUrl;

const UsersLaunchpad = () => {
  const [records, setRecords] = useState([]);

  // show modal
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);
  const [launchpadData, setLaunchpadData] = useState([]);
  const [dataStatus, setDataStatus] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      key: "#",
      text: "Id",
      className: "id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "user_id",
      text: "User Id",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.email}</span>
          </>
        );
      },
    },

    {
      key: "launchpad_id",
      text: "Launchpad Id",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.launchpad_id} </span>
            <button
              className="btn btn-primary btn-sm mr-2"
              onClick={() => userLaunchpadApi(record)}
              title="Launchpad Info"
            >
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </>
        );
      },
    },
    {
      key: "coin_amount",
      text: "Coin Amount",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>
              {record.coin_amount} {record.short_name}
            </span>
          </>
        );
      },
    },

    {
      key: "total_buy",
      text: "Spent Amount",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            {record.total_buy} {"USDT"}
          </Fragment>
        );
      },
    },
    {
      key: "created_at",
      text: "Date & Time",
      className: "created_at",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{Moment.utc(record.created_at).local().format("lll")}</>;
      },
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            {record.status == "0" ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={() => deleteRecordMgs(record)}
              >
                Block
              </button>
            ) : (
              "-"
            )}
          </Fragment>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "country",
    no_data_text: "No user found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  const userLaunchpadApi = (record) => {
    let data = {
      id: record.launchpad_id,
    };

    axios
      .post(baseUrl + "/api/getLaunchPadById", data)
      .then((res) => {
        setLaunchpadData(res.data.data);
        setDataStatus(res.data.status);
      })
      .catch();

    setIsShowModalOpen(true);
  };

  const getData = () => {
    axios
      .get(baseUrl + "/api/getAllUsersLaunchpad")
      .then((res) => {
        setRecords(res.data.data);
      })
      .catch();
  };

  const deleteRecordMgs = (record) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to stop this user launchpad?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(record),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteRecord = (record) => {
    axios
      .post(baseUrl + "/api/stacking-user-delete", { id: record.id })
      .then((res) => {
        if (res.data.status === true) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          getData();
        } else {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    getData();
  };

  const tableDataHtml = () => {
    if (dataStatus == true) {
      const html = [];
      launchpadData &&
        launchpadData.map((value, i) => {
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>
                <>
                  {" "}
                  {
                    <img
                      style={{ maxWidth: "40px" }}
                      className="mr-2"
                      src={baseUrl + "/static/currencyImage/" + value.logo}
                      alt=""
                    />
                  }{" "}
                  {value.short_name}
                </>
              </td>
              <td>{value.ico_price} USDT</td>
              <td>
                {value.session_supply} {value.short_name}
              </td>
            </tr>
          );
        });
      return html;
    }
  };

  const closeShowModal = () => {
    setIsShowModalOpen(false);
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />

        {/* show modal */}
        <Modal
          show={isShowModalOpen}
          onHide={closeShowModal}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>Launchpad Info</Modal.Title>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeShowModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <table className="table w-100 no-scroll">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Token Name</th>
                  <th>ICO Price(USDT)</th>
                  <th>Session Supply</th>
                </tr>
              </thead>
              <tbody>{tableDataHtml()}</tbody>
            </table>
          </Modal.Body>
        </Modal>
        {/* end show modal */}

        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mt-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>

            <h4 className="mt-2 man_hadding mb-3">Users Launchpad List</h4>
            <ReactDatatable
              config={config}
              records={records}
              columns={columns}
              onPageChange={pageChange()}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default UsersLaunchpad;
