import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from "./baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Modal } from "react-bootstrap";
const baseUrl = myConstList.baseUrl;

const LaunchPad = () => {
  const [records, setRecords] = useState([]);
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [sessionSupply, setSessionSupply] = useState("");
  const [ICOPrice, setICOPrice] = useState("");
  const [ICOStartDate, setICOStartDate] = useState("");
  const [ICOEndDate, setICOEndDate] = useState("");
  const [chain, setChain] = useState("");
  const [BSCChain, setBSCChain] = useState("");
  const [documentFile, setDocumentFile] = useState("");
  const [logo, setLogo] = useState("");
  const [description, setDescription] = useState("");
  const [highlight, setHighlight] = useState("");
  const [strength, setStrength] = useState("");
  const [userUploadLogo, setUserUploadLogo] = useState("/img/support.jpg");
  const [maxPurchase, setMaxPurchase] = useState("");
  const [socialArr, setSocialArr] = useState([{ link: "", handle: "" }]);

  // errors
  const [errors, setErrors] = useState({
    name: "",
    shortName: "",
    email: "",
    number: "",
    website: "",
    sessionSupply: "",
    ICOPrice: "",
    ICOStartDate: "",
    ICOEndDate: "",
    chain: "",
    BSCChain: "",
    documentFile: "",
    logo: "",
    highlight: "",
    strength: "",
    description: "",
    maxPurchase: "",
  });

  const [socialArrError, setSocialArrError] = useState([
    { link: "", handle: "" },
  ]);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [btnAdd, setBtnAdd] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      text: "Id",
      className: "id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "token_name",
      text: "Token Name",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.token_name} </span>
          </>
        );
      },
    },
    {
      key: "short_name",
      text: "Short Name",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.short_name} </span>
          </>
        );
      },
    },
    {
      key: "email",
      text: "Email",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.email}</span>
          </>
        );
      },
    },
    {
      key: "number",
      text: "Mobile Number",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.number}</span>
          </>
        );
      },
    },
    {
      key: "request_status",
      text: "Request Status",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>
              {record.request_status == "1" ? "Accepted" : ""}
              {record.request_status == "2" ? "Rejected" : ""}
              {record.request_status == "0" && (
                <select onClick={(e) => requestStatus(record, e.target.value)}>
                  <option value={"0"}>Pending</option>
                  <option value={"1"}>Accept</option>
                  <option value={"2"}>Reject</option>
                </select>
              )}
            </span>
          </>
        );
      },
    },
    {
      key: "current_status",
      text: "Current Status",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        var today = new Date();

        // Get the year, month, and day from the date object

        var year = today.getFullYear();
        var month = (today.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-indexed
        var day = today.getDate().toString().padStart(2, "0");

        // Create the formatted date string
        var formattedDate = `${year}-${month}-${day}`;

        return (
          <Fragment>
            {record.ice_start_date <= formattedDate &&
            formattedDate <= record.ice_end_date
              ? "Ongoing"
              : ""}
            {record.ice_start_date > formattedDate ? "Upcoming" : ""}
            {formattedDate > record.ice_end_date ? "Completed" : ""}
          </Fragment>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-primary btn-sm"
              onClick={() =>
                (window.location.href = "/launchPadDetails/" + record.id)
              }
              title={"View"}
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>

            <button
              className={
                record.hidden == 1
                  ? "btn btn-danger btn-sm"
                  : "btn btn-success btn-sm"
              }
              title={record.hidden == 1 ? "Unhide LaunchPad" : "Hide LaunchPad"}
              onClick={() => {
                blockStatus(record, record.hidden);
              }}
            >
              <i class="fa fa-lock"></i>
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => deleteRecordMgs(record)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "country",
    no_data_text: "No user found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  const getData = () => {
    axios
      .get(baseUrl + "/api/getAllLaunchPad")
      .then((res) => {
        setRecords(res.data.data);
      })
      .catch();
  };

  const blockStatus = (record, status) => {
    status == 0
      ? confirmAlert({
          title: "Confirm to submit",
          message: "Really want to hide this launchpad ?",
          buttons: [
            {
              label: "Yes",
              onClick: () => blockApi(record, status),
            },
            {
              label: "No",
            },
          ],
        })
      : confirmAlert({
          title: "Confirm to submit",
          message: "Really want to unhide this launchpad ?",
          buttons: [
            {
              label: "Yes",
              onClick: () => blockApi(record, status),
            },
            {
              label: "No",
            },
          ],
        });
  };

  const blockApi = (record, value) => {
    let data = {
      status: value,
      id: record.id,
    };
    axios
      .post(baseUrl + "/api/launchPad-block-status", data)
      .then((res) => {
        if (res.data.status === true) {
          toast.dismiss();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          getData();
        } else {
          toast.dismiss();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
  };

  const requestStatus = (record, value) => {
    if (value !== "0") {
      requestStatusAlert(record, value);
    }

    return false;
  };

  const requestStatusAlert = (record, value) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to do the action?",
      buttons: [
        {
          label: "Yes",
          onClick: () => RequestStatusApi(record, value),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const RequestStatusApi = (record, value) => {
    let data = {
      status: value,
      id: record.id,
      token_name: record.token_name,
      short_name: record.short_name,
      icon: record.logo,
    };
    axios
      .post(baseUrl + "/api/launchPad-request-status", data)
      .then((res) => {
        if (res.data.status === true) {
          toast.dismiss();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          getData();
        } else {
          toast.dismiss();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
  };

  const deleteRecordMgs = (record) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to delete this launchpad?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(record),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteRecord = (record) => {
    axios
      .post(baseUrl + "/api/launchpad-delete", { id: record.id })
      .then((res) => {
        if (res.data.status === true) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          getData();
        } else {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
  };

  // add

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const launchpadAdd = (e) => {
    e.preventDefault();

    let errorName = "";

    if (
      !name &&
      !shortName &&
      !email &&
      !number &&
      !website &&
      !ICOPrice &&
      !sessionSupply &&
      !maxPurchase &&
      !ICOStartDate &&
      !ICOEndDate &&
      !chain &&
      !BSCChain &&
      !highlight &&
      !strength &&
      !description
    ) {
      setErrors({
        name: "Please enter token name",
        shortName: "Please enter short name",
        email: "Please enter email",
        number: "Please enter mobile number",
        website: "Please enter website",
        ICOPrice: "Please enter ICO price",
        sessionSupply: "Please enter session supply",
        maxPurchase: "Please enter max purchase amount",
        ICOStartDate: "Please enter ICO start date",
        ICOEndDate: "Please enter ICO end date",
        chain: "Please enter chain",
        BSCChain: "Please enter contract address",
        highlight: "Please enter project highlight",
        strength: "Please enter project strength",
        description: "Please enter description",
      });
      return false;
    }

    if (name === "" || !name) {
      setErrors({
        name: "Please enter token name",
      });
      return false;
    }

    errorName = checkSpace("token name", name);
    if (errorName.length > 0) {
      setErrors({
        name: errorName,
      });
      return false;
    }

    if (shortName === "" || !shortName) {
      setErrors({
        shortName: "Please enter short name",
      });
      return false;
    }

    errorName = checkSpace("short name", shortName);
    if (errorName.length > 0) {
      setErrors({
        shortName: errorName,
      });
      return false;
    }

    if (email === "" || !email) {
      setErrors({
        email: "Please enter email",
      });
      return false;
    }

    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (!email.match(emailReg)) {
      setErrors({
        email: "Please enter valid email",
      });
      return false;
    }

    if (number === "" || !number) {
      setErrors({
        number: "Please enter mobile number",
      });
      return false;
    }

    // if (number.length > 5) {
    //   setErrors({
    //     number: "Mobile number should be atleast 5 digits",
    //   });
    //   return false;
    // }

    // if (number.length < 15) {
    //   setErrors({
    //     number: "Mobile number should be atleast 15 digits",
    //   });
    //   return false;
    // }

    // const firstDigit = parseInt(number.charAt(0));

    // // Check if the first digit is 6 or greater than 6
    // if (firstDigit < 5) {
    //   setErrors({
    //     number: "Please enter valid mobile number",
    //   });
    //   return false;
    // }

    if (website === "" || !website) {
      setErrors({
        website: "Please enter website",
      });
      return false;
    }

    errorName = checkSpace("website", website);
    if (errorName.length > 0) {
      setErrors({
        website: errorName,
      });
      return false;
    }

    if (ICOPrice === "" || !ICOPrice) {
      setErrors({
        ICOPrice: "Please enter ICO price",
      });
      return false;
    }

    if (sessionSupply === "" || !sessionSupply) {
      setErrors({
        sessionSupply: "Please enter session supply",
      });
      return false;
    }

    if (maxPurchase === "" || !maxPurchase) {
      setErrors({
        maxPurchase: "Please enter max purchase amount",
      });
      return false;
    }

    if (maxPurchase <= 0) {
      setErrors({
        maxPurchase: "Please enter valid max purchase amount",
      });
      return false;
    }

    if (ICOStartDate === "" || !ICOStartDate) {
      setErrors({
        ICOStartDate: "Please enter ICO start date",
      });
      return false;
    }

    if (ICOEndDate === "" || !ICOEndDate) {
      setErrors({
        ICOEndDate: "Please enter ICO end date",
      });
      return false;
    }

    if (ICOEndDate == ICOStartDate) {
      setErrors({
        ICOEndDate: "End date cannot be same as start date",
      });
      return false;
    }

    if (chain === "" || !chain) {
      setErrors({
        chain: "Please enter chain",
      });
      return false;
    }

    errorName = checkSpace("chain", chain);
    if (errorName.length > 0) {
      setErrors({
        chain: errorName,
      });
      return false;
    }

    if (BSCChain === "" || !BSCChain) {
      setErrors({
        BSCChain: "Please enter contract address",
      });
      return false;
    }

    errorName = checkSpace("contract address", BSCChain);
    if (errorName.length > 0) {
      setErrors({
        BSCChain: errorName,
      });
      return false;
    }

    if (highlight === "" || !highlight) {
      setErrors({
        highlight: "Please enter project highlight",
      });
      return false;
    }

    errorName = checkSpace("project highlight", highlight);
    if (errorName.length > 0) {
      setErrors({
        highlight: errorName,
      });
      return false;
    }

    if (strength === "" || !strength) {
      setErrors({
        strength: "Please enter project strength",
      });
      return false;
    }

    errorName = checkSpace("project strength", strength);
    if (errorName.length > 0) {
      setErrors({
        strength: errorName,
      });
      return false;
    }

    if (description === "" || !description) {
      setErrors({ description: "Please enter description" });
      return false;
    }

    errorName = checkSpace("description", description);
    if (errorName.length > 0) {
      setErrors({ description: errorName });
      return false;
    }

    if (!documentFile || documentFile.length < 0) {
      setErrors({ documentFile: "Please select document" });
      return false;
    }
    if (!documentFile.name.match(/\.(pdf)$/)) {
      setErrors({ documentFile: "Please select valid input pdf" });
      return false;
    }
    if (documentFile.size >= 2048000 || documentFile.size < 10240) {
      setErrors({ documentFile: "Document size should be 10KB to 2MB" });
      return false;
    }

    if (!logo || logo.length < 0) {
      setErrors({ logo: "Please select document" });
      return false;
    }
    if (!logo.name.match(/\.(jpg|jpeg|png)$/)) {
      setErrors({ logo: "Please select valid image jpg|jpeg|png" });
      return false;
    }
    if (logo.size >= 2048000 || logo.size < 10240) {
      setErrors({ logo: "Document size should be 10KB to 2MB" });
      return false;
    }

    for (let i = 0; i < socialArr.length; i++) {
      const element = socialArr[i];
      let inventoryError = [...socialArrError];
      var socialLink = element.link;
      var socialName = element.handle;

      if (!socialLink) {
        inventoryError[i].link = "Please enter social link";
        setSocialArrError(inventoryError);
        return false;
      }

      if (!socialName || socialName === "Please select") {
        inventoryError[i].handle = "Please select social media";
        setSocialArrError(inventoryError);
        return false;
      }
    }
    setBtnAdd(true);

    const formData = new FormData();

    formData.append("tokenName", name);
    formData.append("shortName", shortName);
    formData.append("email", email);
    formData.append("mobile", number);
    formData.append("website", website);
    formData.append("ICOPrice", ICOPrice);
    formData.append("sessionSupply", sessionSupply);
    formData.append("maxPurchase", maxPurchase);
    formData.append("startDate", ICOStartDate);
    formData.append("endDate", ICOEndDate);
    formData.append("chain", chain);
    formData.append("BSCChain", BSCChain);
    formData.append("highlight", highlight);
    formData.append("strength", strength);
    formData.append("Document", documentFile);
    formData.append("Logo", logo);
    formData.append("socialMedia", JSON.stringify(socialArr));
    formData.append("description", description);

    axios
      .post(baseUrl + "/api/launchPad-add", formData)
      .then((res) => {
        if (res.data.status === true) {
          switcAddModal("close");
          toast.dismiss();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          getData();
        } else {
          toast.dismiss();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setBtnAdd(false);
        }
      })
      .catch((err) => {
        setBtnAdd(false);
        toast.dismiss();
        toast(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    if (e.target.id === "name") {
      setName(value);

      if (value === "" || !value) {
        setErrors({
          name: "Please enter token name",
        });
        return false;
      }
      setErrors({
        name: "",
      });
    }

    if (name === "shortName") {
      const newValue = value.toUpperCase();
      setShortName(newValue);
      if (newValue === "" || !newValue) {
        setErrors({
          shortName: "Please enter short name",
        });
        return false;
      }
      setErrors({
        shortName: "",
      });
    }

    if (name === "email") {
      setEmail(value);
      if (value === "" || !value) {
        setErrors({
          email: "Please enter email",
        });
        return false;
      }

      var emailReg =
        /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

      if (!value.match(emailReg)) {
        setErrors({
          email: "Please enter valid email",
        });
        return false;
      }
      setErrors({
        email: "",
      });
    }

    if (name === "mobile") {
      setNumber(value);

      if (value === "" || !value) {
        setErrors({
          number: "Please enter mobile number",
        });
        return false;
      }

      // if (value.length > 10) {
      //   setErrors({
      //     number: "Mobile number should be of 10 digits",
      //   });

      //   return false;
      // }

      // if (value.length < 10) {
      //   setErrors({
      //     number: "Mobile number should be of 10 digits",
      //   });
      //   return false;
      // }
      setErrors({
        number: "",
      });
    }

    if (name === "website") {
      setWebsite(value);
      if (value === "" || !value) {
        setErrors({
          website: "Please enter website",
        });

        return false;
      }
      setErrors({
        website: "",
      });
    }

    if (name === "ICOPrice") {
      setICOPrice(value);
      if (value === "" || !value) {
        setErrors({
          ICOPrice: "Please enter ICO price",
        });

        return false;
      }
      setErrors({
        ICOPrice: "",
      });
    }

    if (name === "sessionSupply") {
      setSessionSupply(value);
      if (value === "" || !value) {
        setErrors({
          sessionSupply: "Please enter session supply",
        });

        return false;
      }
      setErrors({
        sessionSupply: "",
      });
    }

    if (name === "maxPurchase") {
      setMaxPurchase(value);
      if (value === "" || !value) {
        setErrors({
          maxPurchase: "Please enter max purchase amount",
        });

        return false;
      }
      setErrors({
        maxPurchase: "",
      });
    }

    if (name === "startDate") {
      setICOStartDate(value);
      setErrors({
        ICOStartDate: "",
      });
    }

    if (name === "endDate") {
      setICOEndDate(value);
      setErrors({
        ICOEndDate: "",
      });
    }

    if (name === "chain") {
      setChain(value);
      if (value === "" || !value) {
        setErrors({
          chain: "Please enter chain",
        });

        return false;
      }
      setErrors({
        chain: "",
      });
    }

    if (name === "BSCChain") {
      setBSCChain(value);
      if (value === "" || !value) {
        setErrors({
          BSCChain: "Please enter contract address",
        });

        return false;
      }

      setErrors({
        BSCChain: "",
      });
    }

    if (name === "highlight") {
      setErrors({
        highlight: "Please enter project highlight",
      });

      setHighlight(value);
      if (value === "" || !value) {
        return false;
      }

      setErrors({
        highlight: "",
      });
    }

    if (name === "strength") {
      setStrength(value);
      if (value === "" || !value) {
        setErrors({
          strength: "Please enter project strength",
        });

        return false;
      }

      setErrors({
        strength: "",
      });
    }

    if (name === "document") {
      setDocumentFile(e.target.files[0]);

      var temp_doc = e.target.files[0];

      if (temp_doc === false) {
        setErrors({ documentFile: "Please select document" });

        return false;
      }
      if (!temp_doc.name.match(/\.(pdf)$/)) {
        setErrors({ documentFile: "Please select valid input pdf" });
        return false;
      }
      if (temp_doc.size >= 3e6 || temp_doc.size < 10000) {
        setErrors({ documentFile: "Document size should be 10KB to 3MB" });

        return false;
      }
      setErrors({ documentFile: "" });
    }

    if (name === "logo") {
      setLogo(e.target.files[0]);

      var temp_logo = e.target.files[0];

      if (temp_logo === false) {
        setUserUploadLogo(false);
        setErrors({ logo: "Please select document" });

        return false;
      }
      if (!temp_logo.name.match(/\.(jpg|jpeg|png)$/)) {
        setUserUploadLogo(false);
        setErrors({ logo: "Please select valid image jpg|jpeg|png" });

        return false;
      }
      if (temp_logo.size >= 1000000 || temp_logo.size < 10000) {
        setErrors({ logo: "Document size should be 10KB to 1MB" });

        return false;
      }

      setUserUploadLogo(URL.createObjectURL(e.target.files[0]));
      setErrors({ logo: "" });
    }

    if (name === "description") {
      setDescription(value);
      if (value === "" || !value) {
        setErrors({
          description: "Please enter description",
        });

        return false;
      }
      setErrors({
        description: "",
      });
    }
  };

  const handleChangeSocial = (i, e) => {
    let newFormValues = [...socialArr];
    newFormValues[i][e.target.name] = e.target.value;
    // error //
    let newFormValuesError = [...socialArrError];
    newFormValuesError[i][e.target.name] = e.target.value;
    let tempErr = "Please enter social link";
    setSocialArr(newFormValues);

    if (e.target.name === "handle") {
      if (
        !newFormValues[i].handle ||
        newFormValues[i].handle === "Please select"
      ) {
        newFormValuesError[i][e.target.name] = "";

        newFormValuesError[i][e.target.name] = "Please select social media";
        setSocialArrError(newFormValuesError);

        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setSocialArrError(newFormValuesError);
      }
    }

    if (e.target.name === "link") {
      if (!newFormValues[i].link) {
        newFormValuesError[i][e.target.name] = tempErr;
        setSocialArrError(newFormValuesError);
        return false;
      } else {
        newFormValuesError[i][e.target.name] = "";
        setSocialArrError(newFormValuesError);
      }
    }
  };

  const addFormFields = (e) => {
    e.preventDefault();
    setSocialArr([...socialArr, { handle: "", link: "" }]);

    setSocialArrError([...socialArrError, { handle: "", link: "" }]);
  };

  const removeFormFields = (i, e) => {
    e.preventDefault();
    let newFormValues = [...socialArr];
    newFormValues.splice(i, 1);
    setSocialArr(newFormValues);

    let newFormValuesError = [...socialArrError];
    newFormValuesError.splice(i, 1);
    setSocialArrError(newFormValuesError);
  };

  const switcAddModal = (type) => {
    setErrors({});
    setSocialArr([{ link: "", handle: "" }]);
    setSocialArrError([{ link: "", handle: "" }]);
    setName("");
    setShortName("");
    setEmail("");
    setNumber("");
    setWebsite("");
    setICOPrice("");
    setSessionSupply("");
    setMaxPurchase("");
    setICOStartDate("");
    setICOEndDate("");
    setChain("");
    setBSCChain("");
    setDocumentFile("");
    setLogo("");
    setDescription("");
    setBtnAdd(false);

    if (type === "close") {
      setIsAddModalOpen(false);
    }

    if (type === "open") {
      setIsAddModalOpen(true);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />

        {/* add modal */}
        <Modal
          show={isAddModalOpen}
          onHide={() => switcAddModal("close")}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>Add LaunchPad</Modal.Title>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => switcAddModal("close")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <form noValidate onSubmit={launchpadAdd} id="add-user">
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Token Name</label>
                    <input
                      type="text"
                      id="name"
                      onChange={handleChange101}
                      value={name}
                      placeholder="Token Name"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Short Name</label>
                    <input
                      type="text"
                      name="shortName"
                      onChange={handleChange101}
                      value={shortName}
                      placeholder="Short Name"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.shortName}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      onChange={handleChange101}
                      value={email}
                      placeholder="Email"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.email}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Mobile Number</label>
                    <input
                      type="number"
                      name="mobile"
                      onKeyDown={handleValidation}
                      onChange={handleChange101}
                      value={number}
                      placeholder="Mobile Number"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.number}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Website</label>
                    <input
                      type="text"
                      name="website"
                      onChange={handleChange101}
                      value={website}
                      placeholder="Website"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.website}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>ICO Price(USDT)</label>
                    <input
                      type="number"
                      name="ICOPrice"
                      onKeyDown={handleValidation}
                      onChange={handleChange101}
                      value={ICOPrice}
                      placeholder="ICO Price(USDT)"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.ICOPrice}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Session Supply</label>
                    <input
                      type="number"
                      name="sessionSupply"
                      onChange={handleChange101}
                      onKeyDown={handleValidation}
                      value={sessionSupply}
                      placeholder="Session Supply"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.sessionSupply}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Max Purchase Amount</label>
                    <input
                      type="number"
                      name="maxPurchase"
                      onChange={handleChange101}
                      onKeyDown={handleValidation}
                      value={maxPurchase}
                      placeholder="Max Purchase Amount"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.maxPurchase}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>ICO Start Date</label>
                    <input
                      type="Date"
                      name="startDate"
                      onChange={handleChange101}
                      value={ICOStartDate}
                      placeholder=""
                      className="form-control"
                    />
                    <span className="text-danger">{errors.ICOStartDate}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>ICO End Date</label>
                    <input
                      type="date"
                      name="endDate"
                      onChange={handleChange101}
                      value={ICOEndDate}
                      placeholder=""
                      className="form-control"
                    />
                    <span className="text-danger">{errors.ICOEndDate}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Chain</label>
                    <input
                      type="text"
                      name="chain"
                      onChange={handleChange101}
                      value={chain}
                      placeholder="Chain"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.chain}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Contract Address</label>
                    <input
                      type="text"
                      name="BSCChain"
                      onChange={handleChange101}
                      value={BSCChain}
                      placeholder="Contract Address"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.BSCChain}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Project Highlight</label>
                    <input
                      type="text"
                      name="highlight"
                      onChange={handleChange101}
                      value={highlight}
                      placeholder="Project Highlight"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.highlight}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Project Strength</label>
                    <input
                      type="text"
                      name="strength"
                      onChange={handleChange101}
                      value={strength}
                      placeholder="Project Strength"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.strength}</span>
                  </div>
                </div>

                {/* <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <label>Social Media</label>
                        <input
                          type="text"
                          name="socialMedia"
                          onChange={handleChange101}
                          value={socialMedia}
                          placeholder="Social Media"
                          className="form-control"
                        />
                        <span className="text-danger">
                          {errors.socialMedia}
                        </span>
                      </div>
                    </div> */}

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      placeholder="Description"
                      onChange={handleChange101}
                      name="description"
                      value={description}
                      className="form-control"
                    ></textarea>
                    <span className="text-danger">{errors.description}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Documents</label>
                    <input
                      type="file"
                      name="document"
                      placeholder=""
                      onChange={handleChange101}
                      className="form-control"
                      accept=".pdf"
                    />
                    <span className="text-danger">{errors.documentFile}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <img
                      id="file-image"
                      src={userUploadLogo != false ? userUploadLogo : ""}
                      className="img-fluid"
                      alt="Preview"
                      // class="hidden"
                    />
                    <label>Logo</label>
                    <input
                      type="file"
                      name="logo"
                      onChange={handleChange101}
                      placeholder=""
                      accept="image/*"
                      className="form-control"
                    />
                    <span className="text-danger">{errors.logo}</span>
                  </div>
                </div>

                {socialArr.map((element, index) => (
                  <>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Link</label>
                          <input
                            type="text"
                            name="link"
                            placeholder="Enter Link"
                            className="form-control"
                            value={element.link || ""}
                            onChange={(e) => handleChangeSocial(index, e)}
                          />
                          <p style={{ color: "red", margin: "0" }}>
                            {" "}
                            {socialArrError[index].link}{" "}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="add-row-group d-flex align-items-center justify-content-between">
                          <div className="form-group">
                            <label>Social Media</label>
                            <select
                              className="form-control"
                              name="handle"
                              value={element.handle || ""}
                              onChange={(e) => handleChangeSocial(index, e)}
                            >
                              <option value={"Please select"}>
                                Please select
                              </option>
                              <option value={"Facebook"}>Facebook</option>
                              <option value={"Instagram"}>Instagram</option>
                              <option value={"LinkedIn"}>LinkedIn</option>
                              <option value={"Medium"}>Medium</option>
                              <option value={"Reddit"}>Reddit</option>
                              <option value={"Telegram"}>Telegram</option>
                              <option value={"Twitter"}>Twitter</option>
                            </select>
                            <p style={{ color: "red" }}>
                              {" "}
                              {socialArrError[index].handle}{" "}
                            </p>
                          </div>
                          <div className="add-row">
                            {index ? (
                              <button
                                className="remove btn-danger"
                                // href="#!"
                                onClick={(e) => removeFormFields(index, e)}
                              >
                                -
                              </button>
                            ) : null}
                            <button
                              className="add-btn add btn-primary"
                              // href="#!"
                              onClick={(e) => addFormFields(e)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              form="add-user"
              type="submit"
              className="btn btn-primary"
              disabled={btnAdd}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
        {/* end add modal */}

        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mt-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>

            <button
              className="btn btn-primary float-right"
              onClick={() => switcAddModal("open")}
            >
              <FontAwesomeIcon icon={faPlus} /> Add{" "}
            </button>
            <h4 className="mt-2 man_hadding mb-3">LaunchPad List</h4>
            <ReactDatatable
              config={config}
              records={records}
              columns={columns}
              onPageChange={pageChange()}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default LaunchPad;
