import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import SuportUpdateModal from "../partials/SuportUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import * as myConstList from "./baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
const baseUrl = myConstList.baseUrl;
class Suport extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        // key: "id",
        text: "#",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
        key: "id",
        text: "Ticket Id",
        className: "mobile",
        align: "left",
        sortable: true,
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "mobile_no",
        text: "Mobile No",
        className: "mobile",
        align: "left",
        sortable: true,
      },
      {
        key: "issue",
        text: "Subject",
        className: "subject",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              {console.log("record===>>>>>", record)}
              {this.getNodesToRemoveFromElement(record.issue)}
            </Fragment>
          );
        },
      },

      {
        key: "status",
        text: "Status",
        className: "status",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              {record.status == 0 ? (
                <span style={{ color: "red" }}>Pending</span>
              ) : (
                <span style={{ color: "green" }}>Resolved</span>
              )}
            </Fragment>
          );
        },
      },
      {
        key: "createAt",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{Moment(record.createAt).format("lll")}</Fragment>;
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <div className="position-relative chat-count admin-chat">
                <a
                  href={
                    "/chat-room/" + btoa(record.id) + "/" + record.seenStatus
                  }
                  className="btn btn-primary mr-2 btn-sm"
                >
                  Chat
                </a>
                {/* {record.seenStatus === "0" && (
                  <span className="position-absolute">+1</span>
                )} */}
              </div>
              {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecordMgs(record)}>
                                <i className="fa fa-trash"></i>
                            </button> */}
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Suport Managment",
      no_data_text: "No user found!",
      button: {
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
    };

    this.state = {
      currentRecord: {
        id: "",
        subject: "",
        date: "",
        status: "",
        issueImage: "",
      },
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (
      this.props.auth.user.suportManagement !== undefined &&
      this.props.auth.user.suportManagement !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.auth.user.suportManagement !== undefined &&
      nextProps.auth.user.suportManagement !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    this.getData();
  }

  getData() {
    axios
      .get(baseUrl + "/api/userAllHelp-data")
      .then((res) => {
        this.setState({ records: res.data });
      })
      .catch();
  }

  supportClick = (e) => {
    var html = [];

    this.state.records.map((value) => {
      console.log("valuevaluevaluevalue", value.status);
      if (e.target.value == "Pending") {
        if (value.status == 0) {
          html.push(value);
        }
      }
      if (e.target.value == "Solved") {
        if (value.status == 1) {
          html.push(value);
        }
      }
      if (e.target.value == "select") {
        html.push(value);
      }
    });
    this.setState({ FilterRecords: html });
  };

  showFilterList = () => {
    console.log("showqfilterlist");

    const html = [];

    html.push(
      <select
        className="form-control"
        id="exchange"
        name="supportFilter"
        onClick={this.supportClick}
      >
        <option value="select" id="">
          select
        </option>
        <option value="Pending">Pending</option>
        <option value="Solved">Resolved</option>
      </select>
    );

    return <div className="support-select">{html}</div>;
  };

  editRecord(record) {
    this.setState({ currentRecord: record });
  }
  deleteRecordMgs(record) {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to delete this Ticket Id?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteRecord(record),
        },
        {
          label: "No",
        },
      ],
    });
  }
  deleteRecord(record) {
    axios
      .post(baseUrl + "/api/userAllHelp-delete", { id: record.id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    this.getData();
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }
  getNodesToRemoveFromElement = (stringContent) => {
    var div = document.createElement("div");
    div.innerHTML = stringContent;
    var text = div.textContent || div.innerText || "";
    return text;
  };
  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid table_last ">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>

              <h4 className="mt-2 man_hadding mb-3">Users Query List</h4>
              <div className="white_box">
                {this.showFilterList()}
                <ReactDatatable
                  config={this.config}
                  records={
                    this.state.FilterRecords
                      ? this.state.FilterRecords
                      : this.state.records
                  }
                  columns={this.columns}
                  onPageChange={this.pageChange.bind(this)}
                />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

Suport.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps)(Suport);
