import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateBank } from "../../actions/AdminBankAction";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from '../../actions/baseUrl';

class AdminBankUpdateModal extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      id: this.props.currentRecord.id,
      name: this.props.currentRecord.name,
      errors: {},
      coinImage: "",
      icon: this.props.currentRecord.icon,
      discription: this.props.currentRecord.discription
    };
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.currentRecord) {
      console.log("nextProps.currentRecord.is_deposit", nextProps.currentRecord)

      this.setState({
        id: nextProps.currentRecord.id,
        accountholder: nextProps.currentRecord.accountholder,
        accountnumber: nextProps.currentRecord.accountnumber,
        bankname: nextProps.currentRecord.bankName,
        branchname: nextProps.currentRecord.branchname,
        ifsccode: nextProps.currentRecord.ifsccode,
        upi_image: nextProps.currentRecord.upi_image,
        upi_id: nextProps.currentRecord.upi_id,
      })
      //console.log("chain",nextProps.currentRecord.chain_type)
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }

  }

  onChange = e => {
    console.log("valueof e", e)

    if (e.target.name === 'currencyImage') {
      var currencyImage = e.target.files[0];

      var fileSize = e.target.files[0].size;
      console.log(this.state.invalidImage);
      if (!currencyImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        this.setState({ invalidImage: "Please select valid image jpeg,png,gif.", userUploadImage: false });
        return false;
      }
      this.setState({
        coinImage: currencyImage,
      });
    }
    else {
      this.setState({ [e.target.id]: e.target.value })
    }
  }

  onCryptoUpdate = e => {
    e.preventDefault();
    console.log("console.log", this.state.newsImage, this.state.name, this.state.discription)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const formData = new FormData();
    formData.append('id', this.state.id);
    formData.append('currencyImage', this.state.coinImage);
    formData.append('bankname', this.state.bankname);
    formData.append('accountnumber', this.state.accountnumber);
    formData.append('ifsccode', this.state.ifsccode);
    formData.append('accountholder', this.state.accountholder);
    formData.append('branchname', this.state.branchname);
    formData.append('upi_id', this.state.upi_id);
    this.props.updateBank(formData, config);
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-user-modal" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Bank</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onCryptoUpdate} id="update-user">
                  <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Bank Name</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.bankname}
                          id="bankname"
                          name="bankname"
                          type="text"
                          error={errors.bankname}
                          className={classnames("form-control", {
                            invalid: errors.bankname
                          })} />
                        <span className="text-danger">{errors.bankname}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Account Number</label>
                        <textarea
                          onChange={this.onChange}
                          value={this.state.accountnumber}
                          id="accountnumber"
                          name="accountnumber"
                          type="text"
                          error={errors.accountnumber}
                          className={classnames("form-control", {
                            invalid: errors.accountnumber
                          })} />
                        <span className="text-danger">{errors.accountnumber}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">IFSC Code</label>
                        <textarea
                          onChange={this.onChange}
                          value={this.state.ifsccode}
                          id="ifsccode"
                          name="ifsccode"
                          type="text"
                          error={errors.ifsccode}
                          className={classnames("form-control", {
                            invalid: errors.ifsccode
                          })} />
                        <span className="text-danger">{errors.ifsccode}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Account Holder Name</label>
                        <textarea
                          onChange={this.onChange}
                          value={this.state.accountholder}
                          id="accountholder"
                          name="accountholder"
                          type="text"
                          error={errors.accountholder}
                          className={classnames("form-control", {
                            invalid: errors.accountholder
                          })} />
                        <span className="text-danger">{errors.accountholder}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Branch Name</label>
                        <textarea
                          onChange={this.onChange}
                          value={this.state.branchname}
                          id="branchname"
                          name="branchname"
                          type="text"
                          error={errors.branchname}
                          className={classnames("form-control", {
                            invalid: errors.branchname
                          })} />
                        <span className="text-danger">{errors.branchname}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">UPI Id</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.upi_id}
                          id="upi_id"
                          name="upi_id"
                          type="text"
                          error={errors.upi_id}
                          className={classnames("form-control", {
                            invalid: errors.upi_id
                          })} />
                        <span className="text-danger">{errors.upi_id}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">UPI Image</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.currencyImage}
                          id="currencyImage"
                          name="currencyImage"
                          type="file"
                          error={errors.currencyImage}
                          className={classnames("form-control", {
                            invalid: errors.currencyImage
                          })} />
                        <span className="text-danger">{errors.currencyImage}</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  form="update-user"
                  type="submit"
                  className="btn btn-primary">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AdminBankUpdateModal.propTypes = {
  updateBank: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { updateBank }
)(withRouter(AdminBankUpdateModal));
