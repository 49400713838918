import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from "./baseUrl";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const baseUrl = myConstList.baseUrl;

const ListingEdit = () => {
  const params = useParams();
  const listingId = params.id;

  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [logo, setLogo] = useState("");
  const [issuePrice, setIssuePrice] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [maxSupply, setMaxSupply] = useState("");
  const [circulatingSupply, setCirculatingSupply] = useState("");
  const [explorer, setExplorer] = useState("");
  const [website, setWebsite] = useState("");
  const [document, setDocument] = useState("");
  const [introduction, setIntroduction] = useState("");

  const [btnEdit, setBtnEdit] = useState(false);
  const [userUploadLogo, setUserUploadLogo] = useState("/img/support.jpg");

  const [userUploadDoc, setUserUploadDoc] = useState("");
  const [showLogoImg, setShowLogoImg] = useState("");

  // errors

  const [nameErr, setNameErr] = useState("");
  const [shortNameErr, setShortNameErr] = useState("");
  const [logoErr, setLogoErr] = useState("");
  const [issuePriceErr, setIssuePriceErr] = useState("");
  const [issueDateErr, setIssueDateErr] = useState("");
  const [maxSupplyErr, setMaxSupplyErr] = useState("");
  const [circulatingSupplyErr, setCirculatingSupplyErr] = useState("");
  const [explorerErr, setExplorerErr] = useState("");
  const [websiteErr, setWebsiteErr] = useState("");
  const [documentErr, setDocumentErr] = useState("");
  const [introductionErr, setIntroductionErr] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .post(baseUrl + "/api/getListingById", { id: listingId })
      .then((res) => {
        var resp = res.data.data[0];

        setName(resp.token_name);
        setShortName(resp.short_name);
        setWebsite(resp.website);
        setExplorer(resp.explorer);
        setMaxSupply(resp.max_supply);
        setCirculatingSupply(resp.circulating_supply);
        setIssueDate(resp.issue_date);
        // setDocument(resp.document);
        setUserUploadDoc(resp.document);
        setIntroduction(resp.introduction);
        setIssuePrice(resp.issue_price);

        setUserUploadLogo(resp.logo);
      })
      .catch();
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const listingUpdate = (e) => {
    e.preventDefault();
    let errorName = "";
    if (!name) {
      setNameErr("Please enter token name");
      return false;
    }

    errorName = checkSpace("token name", name);
    if (errorName.length > 0) {
      setNameErr(errorName);
      return false;
    }

    if (!shortName) {
      setShortNameErr("Please enter short name");
      return false;
    }

    errorName = checkSpace("short name", shortName);
    if (errorName.length > 0) {
      setShortNameErr(errorName);
      return false;
    }

    if (!issuePrice) {
      setIssuePriceErr("Please enter issue price");
      return false;
    }

    if (issuePrice <= 0) {
      setIssuePriceErr("Please enter valid issue price");
      return false;
    }

    if (!issueDate) {
      setIssueDateErr("Please enter issue date");
      return false;
    }

    if (!maxSupply) {
      setMaxSupplyErr("Please enter max supply");
      return false;
    }

    if (maxSupply <= 0) {
      setMaxSupplyErr("Please enter valid max supply");
      return false;
    }

    if (!circulatingSupply) {
      setMaxSupplyErr("Please enter circulating supply");
      return false;
    }

    if (circulatingSupply <= 0) {
      setMaxSupplyErr("Please enter valid circulating supply");
      return false;
    }

    if (!explorer) {
      setExplorerErr("Please enter explorer link");
      return false;
    }

    errorName = checkSpace("explorer link", explorer);
    if (errorName.length > 0) {
      setExplorerErr(errorName);
      return false;
    }

    if (!website) {
      setWebsiteErr("Please enter website link");
      return false;
    }

    errorName = checkSpace("website", website);
    if (errorName.length > 0) {
      setWebsiteErr(errorName);
      return false;
    }

    if (document) {
      if (document === false) {
        setDocumentErr("Please select document");

        return false;
      }

      if (!document.name.match(/\.(pdf)$/)) {
        setDocumentErr("Please select valid input pdf");
        return false;
      }

      if (document.size >= 5242880 || document.size < 10240) {
        setDocumentErr("Document size should be 10KB to 5MB");
        return false;
      }
    }

    if (logo) {
      if (logo === false) {
        setLogoErr("Please select logo");

        return false;
      }

      if (!logo.name.match(/\.(jpg|jpeg|png)$/)) {
        setLogoErr("Please select valid input jpg,jpeg,png");
        return false;
      }

      if (logo.size >= 2048000 || logo.size < 10240) {
        setLogoErr("Logo size should be 10KB to 2MB");
        return false;
      }
    }

    if (!introduction) {
      setIntroductionErr("Please enter introduction");
      return false;
    }

    errorName = checkSpace("introduction", introduction);
    if (errorName.length > 0) {
      setIntroductionErr(errorName);
      return false;
    }

    setBtnEdit(true);

    const formData = new FormData();
    formData.append("Logo", logo);
    formData.append("Document", document);

    formData.append("tokenName", name);
    formData.append("shortName", shortName);
    formData.append("issuePrice", issuePrice);
    formData.append("issueDate", issueDate);
    formData.append("maxSupply", maxSupply);
    formData.append("circulatingSupply", circulatingSupply);
    formData.append("explorer", explorer);
    formData.append("website", website);
    formData.append("introduction", introduction);

    formData.append("id", listingId);

    axios.post(baseUrl + "/api/listing-edit", formData).then((res) => {
      var resp = res.data;

      if (resp.success === true) {
        toast.dismiss();
        toast(resp.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          window.location.href = "/listing";
        }, 2000);
      } else {
        toast.dismiss();
        toast(resp.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setBtnEdit(false);
      }
    });
  };

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    if (name === "name") {
      // const newValue = value.toUpperCase();
      setName(value);

      if (!value) {
        setNameErr("Please enter token name");
        return false;
      }
      setNameErr("");
    }

    if (name === "shortName") {
      const newValue = value.toUpperCase();
      setShortName(newValue);

      if (!newValue) {
        setShortNameErr("Please enter short name");
        return false;
      }
      setShortNameErr("");
    }

    if (name === "maxSupply") {
      setMaxSupply(value);
      if (!value) {
        setMaxSupplyErr("Please enter max supply");
        return false;
      }
      setMaxSupplyErr("");
    }

    if (name === "circulatingSupply") {
      setCirculatingSupply(value);
      if (!value) {
        setCirculatingSupplyErr("Please enter circulating supply");
        return false;
      }
      setCirculatingSupplyErr("");
    }

    if (name === "issuePrice") {
      setIssuePrice(value);
      if (!value) {
        setIssuePriceErr("Please enter issue price");
        return false;
      }
      setIssuePriceErr("");
    }

    if (name === "issueDate") {
      setIssueDate(value);
      if (!value) {
        setIssueDateErr("Please enter issue date");
        return false;
      }
      setIssueDateErr("");
    }

    if (name === "document") {
      setDocument(e.target.files[0]);

      var temp_doc = e.target.files[0];

      if (temp_doc === false) {
        setUserUploadDoc(false);
        setDocumentErr("Please select document");
        return false;
      }

      if (!temp_doc.name.match(/\.(pdf)$/)) {
        setUserUploadDoc(false);
        setDocumentErr("Please select valid input pdf");
        return false;
      }

      if (temp_doc.size >= 5242880 || temp_doc.size < 10240) {
        setUserUploadDoc(false);
        setDocumentErr("Docment size should be 10KB to 5MB");
        return false;
      }

      setDocumentErr("");
      setUserUploadDoc(URL.createObjectURL(e.target.files[0]));
      // setUserUploadDoc(URL.createObjectURL(e.target.files[0]));
    }

    if (name === "logo") {
      setLogo(e.target.files[0]);

      var temp_logo = e.target.files[0];

      if (temp_logo === false) {
        setShowLogoImg(false);
        setLogoErr("Please select logo");
        return false;
      }

      if (!temp_logo.name.match(/\.(jpg|jpeg|png)$/)) {
        setShowLogoImg(false);
        setLogoErr("Please select valid input jpg,jpeg,png");
        return false;
      }

      if (temp_logo.size >= 2048000 || temp_logo.size < 10240) {
        setShowLogoImg(false);
        setLogoErr("Logo image size should be 10KB to 2MB");
        return false;
      }

      setLogoErr("");
      setShowLogoImg(URL.createObjectURL(e.target.files[0]));
    }

    if (name === "explorer") {
      setExplorer(value);
      if (!value) {
        setExplorerErr("Please enter explorer link");
        return false;
      }
      setExplorerErr("");
    }

    if (name === "website") {
      setWebsite(value);
      if (!value) {
        setWebsiteErr("Please enter website link");
        return false;
      }
      setWebsiteErr("");
    }

    if (name === "introduction") {
      setIntroduction(value);
      if (!value) {
        setIntroductionErr("Please enter introduction");
        return false;
      }
      setIntroductionErr("");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mt-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>

            <h4 className="mt-2 man_hadding mb-3">Listing Edit</h4>
          </div>

          <div className="col-md-12">
            <form onSubmit={listingUpdate} id="update-listing">
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Coin/Token Name</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter Coin/Token Name"
                      className="form-control"
                      onChange={handleChange101}
                      value={name}
                    />
                    <span style={{ color: "red" }}>{nameErr}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Short Name</label>
                    <input
                      type="text"
                      name="shortName"
                      onChange={handleChange101}
                      value={shortName}
                      placeholder="Enter Short Name"
                      className="form-control"
                    />
                    <span style={{ color: "red" }}>{shortNameErr}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Issue Price</label>
                    <input
                      type="number"
                      name="issuePrice"
                      placeholder="Issue Price"
                      className="form-control"
                      onChange={handleChange101}
                      value={issuePrice}
                      onKeyDown={handleValidation}
                    />
                    <span style={{ color: "red" }}>{issuePriceErr}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Issue Date</label>
                    <input
                      type="date"
                      name="issueDate"
                      placeholder="Issue Date"
                      className="form-control"
                      onChange={handleChange101}
                      value={issueDate}
                    />
                    <span style={{ color: "red" }}>{issueDateErr}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Max Supply</label>
                    <input
                      type="number"
                      name="maxSupply"
                      placeholder="Max Supply"
                      className="form-control"
                      onChange={handleChange101}
                      value={maxSupply}
                      onKeyDown={handleValidation}
                    />
                    <span style={{ color: "red" }}>{maxSupplyErr}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Circulating Supply</label>
                    <input
                      type="number"
                      name="circulatingSupply"
                      placeholder="Circulating Supply"
                      className="form-control"
                      onChange={handleChange101}
                      value={circulatingSupply}
                      onKeyDown={handleValidation}
                    />
                    <span style={{ color: "red" }}>{circulatingSupplyErr}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Explorer Link</label>
                    <input
                      type="text"
                      name="explorer"
                      placeholder="Explorer Link"
                      className="form-control"
                      onChange={handleChange101}
                      value={explorer}
                    />
                    <span style={{ color: "red" }}>{explorerErr}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Website Link</label>
                    <input
                      type="text"
                      name="website"
                      placeholder="Website Link"
                      className="form-control"
                      onChange={handleChange101}
                      value={website}
                    />
                    <span style={{ color: "red" }}>{websiteErr}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>White PP Document</label>

                    <a
                      href={baseUrl + "/static/listing/" + userUploadDoc}
                      target="_blank"
                    >
                      {" "}
                      {document == false ? userUploadDoc : ""}{" "}
                    </a>

                    <input
                      type="file"
                      name="document"
                      placeholder=""
                      // value={documentFile}
                      onChange={handleChange101}
                      className="form-control"
                      accept=".pdf"
                    />
                    <span className="text-danger">{documentErr}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Logo</label>
                    <img
                      id="file-image"
                      src={
                        logo == false
                          ? baseUrl + "/static/listing/" + userUploadLogo
                          : showLogoImg
                      }
                      className="img-thumbnail"
                      alt="Preview"
                      // class="hidden"
                    />
                    <input
                      type="file"
                      name="logo"
                      onChange={handleChange101}
                      placeholder=""
                      accept="image/*"
                      className="form-control"
                    />
                    <span className="text-danger">{logoErr}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Introduction</label>
                    <textarea
                      placeholder="Introduction"
                      onChange={handleChange101}
                      name="introduction"
                      value={introduction}
                      className="form-control"
                    ></textarea>
                    <span className="text-danger">{introductionErr}</span>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-danger"
              onClick={() =>
                (window.location.href = "/listingDetails/" + params.id)
              }
              // disabled={btnEdit}
            >
              Back
            </button>

            <button
              form="update-listing"
              type="submit"
              className="btn btn-primary"
              disabled={btnEdit}
            >
              Save
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ListingEdit;
