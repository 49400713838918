import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from "./baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Modal } from "react-bootstrap";
const baseUrl = myConstList.baseUrl;

const Listing = () => {
  const [records, setRecords] = useState([]);

  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [logo, setLogo] = useState("");
  const [issuePrice, setIssuePrice] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [maxSupply, setMaxSupply] = useState("");
  const [circulatingSupply, setCirculatingSupply] = useState("");
  const [explorer, setExplorer] = useState("");
  const [website, setWebsite] = useState("");
  const [document, setDocument] = useState("");
  const [introduction, setIntroduction] = useState("");

  const [userUploadLogo, setUserUploadLogo] = useState("/img/support.jpg");

  // errors

    const [nameErr, setNameErr] = useState("");
    const [shortNameErr, setShortNameErr] = useState("");
    const [logoErr, setLogoErr] = useState("");
    const [issuePriceErr, setIssuePriceErr] = useState("");
    const [issueDateErr, setIssueDateErr] = useState("");
    const [maxSupplyErr, setMaxSupplyErr] = useState("");
    const [circulatingSupplyErr, setCirculatingSupplyErr] = useState("");
    const [explorerErr, setExplorerErr] = useState("");
    const [websiteErr, setWebsiteErr] = useState("");
    const [documentErr, setDocumentErr] = useState("");
    const [introductionErr, setIntroductionErr] = useState("");

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [btnAdd, setBtnAdd] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      text: "Id",
      className: "id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "token_name",
      text: "Token Name",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.token_name} </span>
          </>
        );
      },
    },
    {
      key: "short_name",
      text: "Short Name",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.short_name} </span>
          </>
        );
      },
    },

    {
      key: "issue_price",
      text: "Issue Price",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.issue_price} </span>
          </>
        );
      },
    },

    {
      key: "max_supply",
      text: "Max Supply",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.max_supply} </span>
          </>
        );
      },
    },

    {
      key: "circulating_supply",
      text: "Circulating Supply",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>{record.circulating_supply} </span>
          </>
        );
      },
    },

    {
      key: "request_status",
      text: "Request Status",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <span>
              {record.request_status == "1" ? "Accepted" : ""}
              {record.request_status == "2" ? "Rejected" : ""}
              {record.request_status == "0" && (
                <select onClick={(e) => requestStatus(record, e.target.value)}>
                  <option value={"0"}>Pending</option>
                  <option value={"1"}>Accept</option>
                  <option value={"2"}>Reject</option>
                </select>
              )}
            </span>
          </>
        );
      },
    },
    // {
    //   key: "current_status",
    //   text: "Current Status",
    //   className: "name",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     var today = new Date();

    //     // Get the year, month, and day from the date object

    //     var year = today.getFullYear();
    //     var month = (today.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-indexed
    //     var day = today.getDate().toString().padStart(2, "0");

    //     // Create the formatted date string
    //     var formattedDate = `${year}-${month}-${day}`;

    //     return (
    //       <Fragment>
    //         {record.ice_start_date <= formattedDate &&
    //         formattedDate <= record.ice_end_date
    //           ? "Ongoing"
    //           : ""}
    //         {record.ice_start_date > formattedDate ? "Upcoming" : ""}
    //         {formattedDate > record.ice_end_date ? "Completed" : ""}
    //       </Fragment>
    //     );
    //   },
    // },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-primary btn-sm"
              onClick={() =>
                (window.location.href = "/listingDetails/" + record.id)
              }
              title={"View"}
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>

            {/* <button
              className={
                record.hidden == 1
                  ? "btn btn-danger btn-sm"
                  : "btn btn-success btn-sm"
              }
              title={record.hidden == 1 ? "Unhide Listing" : "Hide Listing"}
              onClick={() => {
                blockStatus(record, record.hidden);
              }}
            >
              <i class="fa fa-lock"></i>
            </button> */}

            <button
              className="btn btn-danger btn-sm"
              onClick={() => deleteRecordMgs(record)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "country",
    no_data_text: "No user found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  const getData = () => {
    axios
      .get(baseUrl + "/api/getAllListing")
      .then((res) => {
        setRecords(res.data.data);
      })
      .catch();
  };

  //   const blockStatus = (record, status) => {
  //     status == 0
  //       ? confirmAlert({
  //           title: "Confirm to submit",
  //           message: "Really want to hide this listing ?",
  //           buttons: [
  //             {
  //               label: "Yes",
  //               onClick: () => blockApi(record, status),
  //             },
  //             {
  //               label: "No",
  //             },
  //           ],
  //         })
  //       : confirmAlert({
  //           title: "Confirm to submit",
  //           message: "Really want to unhide this listing ?",
  //           buttons: [
  //             {
  //               label: "Yes",
  //               onClick: () => blockApi(record, status),
  //             },
  //             {
  //               label: "No",
  //             },
  //           ],
  //         });
  //   };

  //   const blockApi = (record, value) => {
  //     let data = {
  //       status: value,
  //       id: record.id,
  //     };
  //     axios
  //       .post(baseUrl + "/api/listing-block-status", data)
  //       .then((res) => {
  //         if (res.data.status === true) {
  //           toast.dismiss();
  //           toast(res.data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //           getData();
  //         } else {
  //           toast.dismiss();
  //           toast(res.data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //         }
  //       })
  //       .catch();
  //   };

  const requestStatus = (record, value) => {
    if (value !== "0") {
      requestStatusAlert(record, value);
    }

    return false;
  };

  const requestStatusAlert = (record, value) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to do the action?",
      buttons: [
        {
          label: "Yes",
          onClick: () => RequestStatusApi(record, value),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const RequestStatusApi = (record, value) => {
    let data = {
      status: value,
      id: record.id,
      token_name: record.token_name,
      short_name: record.short_name,
      icon: record.logo,
    };
    axios
      .post(baseUrl + "/api/listing-request-status", data)
      .then((res) => {
        if (res.data.status === true) {
          toast.dismiss();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          getData();
        } else {
          toast.dismiss();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
  };

  const deleteRecordMgs = (record) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to delete this listing?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(record),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteRecord = (record) => {
    axios
      .post(baseUrl + "/api/listing-delete", { id: record.id })
      .then((res) => {
        if (res.data.status === true) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          getData();
        } else {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
  };

  // add

  const checkSpace = (name, value) => {
    let error = "";

    let hasLeadingSpace = /^\s/.test(value);
    let hasTrailingSpace = /\s$/.test(value);

    if (hasLeadingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    if (hasTrailingSpace) {
      return (error = `Please enter a valid ${name}`);
    }

    return error;
  };

  const listingAdd = (e) => {
    e.preventDefault();

    if (
      !name &&
      !shortName &&
      !issuePrice &&
      !issueDate &&
      !maxSupply &&
      !circulatingSupply &&
      !explorer &&
      !website &&
      !document &&
      !logo &&
      !introduction
    ) {
      setNameErr("Please enter token name");
      setShortNameErr("Please enter short name");
      setIssuePriceErr("Please enter issue price");
      setIssueDateErr("Please enter issue date");
      setMaxSupplyErr("Please enter max supply");
      setCirculatingSupplyErr("Please enter circulating supply");
      setWebsiteErr("Please enter website link");
      setExplorerErr("Please enter explorer link");
      setDocumentErr("Please select document");
      setLogoErr("Please select logo");
      setIntroductionErr("Please enter introduction");
      return false;
    }

    if (!name) {
      setNameErr("Please enter token name");
      return false;
    }

    let errorName = checkSpace("token name", name);
    if (errorName.length > 0) {
      setNameErr(errorName);
      return false;
    }

    if (!shortName) {
      setShortNameErr("Please enter short name");
      return false;
    }

    errorName = checkSpace("short name", shortName);
    if (errorName.length > 0) {
      setShortNameErr(errorName);
      return false;
    }

    if (!issuePrice) {
      setIssuePriceErr("Please enter issue price");
      return false;
    }

    if (issuePrice <= 0) {
      setIssuePriceErr("Please enter valid issue price");
      return false;
    }

    if (!issueDate) {
      setIssueDateErr("Please enter issue date");
      return false;
    }

    if (!maxSupply) {
      setMaxSupplyErr("Please enter max supply");
      return false;
    }

    if (maxSupply <= 0) {
      setMaxSupplyErr("Please enter valid max supply");
      return false;
    }

    if (!circulatingSupply) {
      setMaxSupplyErr("Please enter circulating supply");
      return false;
    }

    if (circulatingSupply <= 0) {
      setMaxSupplyErr("Please enter valid circulating supply");
      return false;
    }

    if (!explorer) {
      setExplorerErr("Please enter explorer link");
      return false;
    }

    errorName = checkSpace("explorer link", explorer);
    if (errorName.length > 0) {
      setExplorerErr(errorName);
      return false;
    }

    if (!website) {
      setWebsiteErr("Please enter website link");
      return false;
    }

    errorName = checkSpace("website", website);
    if (errorName.length > 0) {
      setWebsiteErr(errorName);
      return false;
    }

    if (!document) {
      setDocumentErr("Please select document");
      return false;
    }

    if (!document.name.match(/\.(pdf)$/)) {
      setDocumentErr("Please select valid input pdf");
      return false;
    }

    if (document.size >= 5242880 || document.size < 10240) {
      setDocumentErr("Document size should be 10KB to 5MB");
      return false;
    }

    if (!logo) {
      setLogoErr("Please select logo");
      return false;
    }

    if (!logo.name.match(/\.(jpg|jpeg|png)$/)) {
      setLogoErr("Please select valid input jpg,jpeg,png");
      return false;
    }

    if (logo.size >= 2048000 || logo.size < 10240) {
      setLogoErr("Logo size should be 10KB to 2MB");
      return false;
    }

    if (!introduction) {
      setIntroductionErr("Please enter introduction");
      return false;
    }

    errorName = checkSpace("introduction", introduction);
    if (errorName.length > 0) {
      setIntroductionErr(errorName);
      return false;
    }

    setBtnAdd(true);

    const formData = new FormData();
    formData.append("Logo", logo);
    formData.append("Document", document);

    formData.append("tokenName", name);
    formData.append("shortName", shortName);
    formData.append("issuePrice", issuePrice);
    formData.append("issueDate", issueDate);
    formData.append("maxSupply", maxSupply);
    formData.append("circulatingSupply", circulatingSupply);
    formData.append("explorer", explorer);
    formData.append("website", website);
    formData.append("introduction", introduction);

    axios
      .post(baseUrl + "/api/listing-add", formData)
      .then((res) => {
        if (res.data.status === true) {
          switcAddModal("close");
          toast.dismiss();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          getData();
        } else {
          toast.dismiss();
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setBtnAdd(false);
        }
      })
      .catch((err) => {
        setBtnAdd(false);
        toast.dismiss();
        toast(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleChange101 = (e) => {
    let { name, value } = e.target;

    if (name === "name") {
      // const newValue = value.toUpperCase();
      setName(value);

      if (!value) {
        setNameErr("Please enter token name");
        return false;
      }
      setNameErr("");
    }

    if (name === "shortName") {
      const newValue = value.toUpperCase();
      setShortName(newValue);

      if (!newValue) {
        setShortNameErr("Please enter short name");
        return false;
      }
      setShortNameErr("");
    }

    if (name === "maxSupply") {
      setMaxSupply(value);
      if (!value) {
        setMaxSupplyErr("Please enter max supply");
        return false;
      }
      setMaxSupplyErr("");
    }

    if (name === "circulatingSupply") {
      setCirculatingSupply(value);
      if (!value) {
        setCirculatingSupplyErr("Please enter circulating supply");
        return false;
      }
      setCirculatingSupplyErr("");
    }

    if (name === "issuePrice") {
      setIssuePrice(value);
      if (!value) {
        setIssuePriceErr("Please enter issue price");
        return false;
      }
      setIssuePriceErr("");
    }

    if (name === "issueDate") {
      setIssueDate(value);
      if (!value) {
        setIssueDateErr("Please enter issue date");
        return false;
      }
      setIssueDateErr("");
    }

    if (name === "document") {
      setDocument(e.target.files[0]);

      var temp_doc = e.target.files[0];

      if (temp_doc === false) {
        setDocumentErr("Please select document");
        return false;
      }

      if (!temp_doc.name.match(/\.(pdf)$/)) {
        setDocumentErr("Please select valid input pdf");
        return false;
      }

      if (temp_doc.size >= 5242880 || temp_doc.size < 10240) {
        setDocumentErr("Docment size should be 10KB to 5MB");
        return false;
      }

      setDocumentErr("");
      // setUserUploadDoc(URL.createObjectURL(e.target.files[0]));
    }

    if (name === "logo") {
      setLogo(e.target.files[0]);

      var temp_logo = e.target.files[0];

      if (temp_logo === false) {
        setUserUploadLogo(false);
        setLogoErr("Please select logo");
        return false;
      }

      if (!temp_logo.name.match(/\.(jpg|jpeg|png)$/)) {
        setUserUploadLogo(false);
        setLogoErr("Please select valid input jpg,jpeg,png");
        return false;
      }

      if (temp_logo.size >= 2048000 || temp_logo.size < 10240) {
        setLogoErr("Logo image size should be 10KB to 2MB");
        return false;
      }

      setLogoErr("");
      setUserUploadLogo(URL.createObjectURL(e.target.files[0]));
    }

    if (name === "explorer") {
      setExplorer(value);
      if (!value) {
        setExplorerErr("Please enter explorer link");
        return false;
      }
      setExplorerErr("");
    }

    if (name === "website") {
      setWebsite(value);
      if (!value) {
        setWebsiteErr("Please enter website link");
        return false;
      }
      setWebsiteErr("");
    }

    if (name === "introduction") {
      setIntroduction(value);
      if (!value) {
        setIntroductionErr("Please enter introduction");
        return false;
      }
      setIntroductionErr("");
    }

    
  };

  const switcAddModal = (type) => {
    // states

    setName("");
    setShortName("");
    setLogo("");
    setIssuePrice("");
    setIssueDate("");
    setMaxSupply("");
    setCirculatingSupply("");
    setExplorer("");
    setWebsite("");
    setDocument("");
    setIntroduction("");

    // errors

    setNameErr("");
    setShortNameErr("");
    setLogoErr("");
    setIssuePriceErr("");
    setIssueDateErr("");
    setMaxSupplyErr("");
    setCirculatingSupplyErr("");
    setExplorerErr("");
    setWebsiteErr("");
    setDocumentErr("");
    setIntroductionErr("");

    setBtnAdd(false);

    if (type === "close") {
      setIsAddModalOpen(false);
    }

    if (type === "open") {
      setIsAddModalOpen(true);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />

        {/* add modal */}
        <Modal
          show={isAddModalOpen}
          onHide={() => switcAddModal("close")}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>Add Listing</Modal.Title>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => switcAddModal("close")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <form noValidate onSubmit={listingAdd} id="add-user">
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Coin/Token Name</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter Coin/Token Name"
                      className="form-control"
                      onChange={handleChange101}
                      value={name}
                    />
                    <span style={{ color: "red" }}>{nameErr}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Short Name</label>
                    <input
                      type="text"
                      name="shortName"
                      onChange={handleChange101}
                      value={shortName}
                      placeholder="Enter Short Name"
                      className="form-control"
                    />
                    <span style={{ color: "red" }}>{shortNameErr}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Issue Price</label>
                    <input
                      type="number"
                      name="issuePrice"
                      placeholder="Issue Price"
                      className="form-control"
                      onChange={handleChange101}
                      value={issuePrice}
                      onKeyDown={handleValidation}
                    />
                    <span style={{ color: "red" }}>{issuePriceErr}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Issue Date</label>
                    <input
                      type="date"
                      name="issueDate"
                      placeholder="Issue Date"
                      className="form-control"
                      onChange={handleChange101}
                      value={issueDate}
                    />
                    <span style={{ color: "red" }}>{issueDateErr}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Max Supply</label>
                    <input
                      type="number"
                      name="maxSupply"
                      placeholder="Max Supply"
                      className="form-control"
                      onChange={handleChange101}
                      value={maxSupply}
                      onKeyDown={handleValidation}
                    />
                    <span style={{ color: "red" }}>{maxSupplyErr}</span>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Circulating Supply</label>
                    <input
                      type="number"
                      name="circulatingSupply"
                      placeholder="Circulating Supply"
                      className="form-control"
                      onChange={handleChange101}
                      value={circulatingSupply}
                      onKeyDown={handleValidation}
                    />
                    <span style={{ color: "red" }}>{circulatingSupplyErr}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Explorer Link</label>
                    <input
                      type="text"
                      name="explorer"
                      placeholder="Explorer Link"
                      className="form-control"
                      onChange={handleChange101}
                      value={explorer}
                    />
                    <span style={{ color: "red" }}>{explorerErr}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>Website Link</label>
                    <input
                      type="text"
                      name="website"
                      placeholder="Website Link"
                      className="form-control"
                      onChange={handleChange101}
                      value={website}
                    />
                    <span style={{ color: "red" }}>{websiteErr}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <img
                      id="file-image"
                      src={userUploadLogo != false ? userUploadLogo : ""}
                      className="img-fluid"
                      alt="Preview"
                      // class="hidden"
                    />
                    <label>Logo</label>
                    <input
                      type="file"
                      name="logo"
                      onChange={handleChange101}
                      placeholder=""
                      accept="image/*"
                      className="form-control"
                    />
                    <span className="text-danger">{logoErr}</span>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <label>White PP Document</label>
                    <input
                      type="file"
                      name="document"
                      placeholder=""
                      onChange={handleChange101}
                      accept=".pdf"
                      className="form-control"
                    />
                    <span style={{ color: "red" }}>{documentErr}</span>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Introduction</label>
                    <textarea
                      name="introduction"
                      placeholder="Introduction"
                      className="form-control"
                      onChange={handleChange101}
                      value={introduction}
                    ></textarea>
                    <span style={{ color: "red" }}>{introductionErr}</span>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              form="add-user"
              type="submit"
              className="btn btn-primary"
              disabled={btnAdd}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
        {/* end add modal */}

        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mt-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>

            <button
              className="btn btn-primary float-right"
              onClick={() => switcAddModal("open")}
            >
              <FontAwesomeIcon icon={faPlus} /> Add{" "}
            </button>
            <h4 className="mt-2 man_hadding mb-3">Listing List</h4>
            <ReactDatatable
              config={config}
              records={records}
              columns={columns}
              onPageChange={pageChange()}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Listing;