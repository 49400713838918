import React, { useState, useEffect } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from "./baseUrl";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

const baseUrl = myConstList.baseUrl;

const LaunchPadDetails = () => {
  const params = useParams();

  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [shortName, setShortName] = useState("");
  const [website, setWebsite] = useState("");
  const [sessionSupply, setSessionSupply] = useState("");
  const [price, setPrice] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [chain, setChain] = useState("");
  const [bscChain, setBscChain] = useState("");
  const [highlight, setHighlight] = useState("");
  const [strength, setStrength] = useState("");
  const [description, setDescription] = useState("");
  const [document, setDocument] = useState("");
  const [logo, setLogo] = useState("");
  const [isBlocked, setIsBlocked] = useState(0);
  const [isHide, setIsHide] = useState(0);
  const [maxPurchase, setMaxPurchase] = useState("");
  const [socialMediaObj, setSocialMediaObj] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .post(baseUrl + "/api/getLaunchPadById", { id: params.id })
      .then((res) => {
        var resp = res.data.data[0];
        setTokenName(resp.token_name);
        setShortName(resp.short_name);
        setEmail(resp.email);
        setMobNo(resp.number);
        setWebsite(resp.website);
        setPrice(resp.ico_price);
        setSessionSupply(resp.session_supply);
        setMaxPurchase(resp.max_purchase);
        setStartDate(resp.ice_start_date);
        setEndDate(resp.ice_end_date);
        setChain(resp.chain);
        setBscChain(resp.bsc_chain);
        setDocument(resp.document);
        setLogo(resp.logo);
        setSocialMediaObj(JSON.parse(resp.social_media));
        setDescription(resp.description);
        setIsBlocked(resp.blocked);
        setIsHide(resp.hidden);
        setHighlight(resp.highlight);
        setStrength(resp.strength);
        setCurrentStatus(resp.request_status);
      });
  };

  const checkStatus = (e) => {
    e.preventDefault();

    var today = new Date();

    // Get the year, month, and day from the date object

    var year = today.getFullYear();
    var month = (today.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-indexed
    var day = today.getDate().toString().padStart(2, "0");

    // Create the formatted date string
    var formattedDate = `${year}-${month}-${day}`;

    if (
      currentStatus == "1" &&
      startDate <= formattedDate &&
      formattedDate <= endDate
    ) {
      toast.dismiss();
      toast.error("Cannot edit in ongoing stage");
      return false;
    } else {
      window.location.href = "/launchPadEdit/" + params.id;
    }
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mt-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>

            <h3 className="mt-2 man_hadding mb-3">LaunchPad Details</h3>
            <div className="in_page row">
              <br />

              <div className="col-md-12">
                <form
                  className="update-detail-form"
                  // onSubmit={this.onRoleUpdate}
                  id="add-user"
                >
                  <div className="white_box">
                    <div className="section-heading d-flex justify-content-between align-items-center">
                      <h4 className="m-0"> </h4>
                      <Link className="btn btn-secondary" to="/launchPad">
                        Back
                      </Link>
                    </div>
                    <div className="tab-content ">
                      <div className="tab-pane active show" id="details">
                        <div className="row">
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Token Name</label>
                              <input
                                value={tokenName}
                                disabled
                                type="text"
                                readOnly={true}
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Short Name</label>
                              <input
                                value={shortName}
                                disabled
                                type="text"
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                value={email}
                                disabled
                                type="text"
                                readOnly={true}
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Mobile Number</label>
                              <input
                                value={mobNo}
                                disabled
                                type="text"
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Website</label>
                              <input
                                value={website}
                                disabled
                                type="text"
                                readOnly={true}
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>ICO Price</label>
                              <input
                                value={price}
                                disabled
                                type="number"
                                readOnly={true}
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Session Supply</label>
                              <input
                                value={sessionSupply}
                                disabled
                                type="number"
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Max Purchase Amount</label>
                              <input
                                value={maxPurchase}
                                disabled
                                type="number"
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>ICE Start Date</label>
                              <input
                                value={startDate}
                                disabled
                                type="date"
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>ICE End Date</label>
                              <input
                                value={endDate}
                                disabled
                                type="text"
                                readOnly={true}
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Chain</label>
                              <input
                                value={chain}
                                disabled
                                type="text"
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>BSC Chain</label>
                              <input
                                value={bscChain}
                                disabled
                                type="text"
                                readOnly={true}
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Project Highlight</label>
                              <input
                                type="text"
                                name="highlight"
                                disabled
                                value={highlight}
                                placeholder="Enter Project Highlight"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Project Strength</label>
                              <input
                                type="text"
                                name="strength"
                                disabled
                                value={strength}
                                placeholder="Enter Project Strength"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Document</label>

                              <a
                                href={baseUrl + "/static/launchpad/" + document}
                                target="_blank"
                                className="form-control"
                              >
                                {" "}
                                {document}
                              </a>
                            </div>
                          </div>
                          {/* <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Social Media</label>
                              <input
                                value={socialMedia}
                                disabled
                                type="text"
                                readOnly={true}
                                className={"form-control"}
                              />
                            </div>
                          </div> */}
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Logo</label>

                              <img
                                id="file-image"
                                src={baseUrl + "/static/launchpad/" + logo}
                                className="img-thumbnail"
                                alt="launchPad_Logo"
                              />
                            </div>
                          </div>
                          <div className=" col-12">
                            <div className="form-group">
                              <label>Description</label>
                              <textarea
                                value={description}
                                disabled
                                type="text"
                                className={"form-control"}
                              />
                            </div>
                          </div>

                          {/* <div className="col-12"> */}
                          {socialMediaObj &&
                            socialMediaObj.length > 0 &&
                            socialMediaObj.map((element, index) => (
                              <>
                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label>Link</label>
                                    <input
                                      type="text"
                                      name="link"
                                      disabled
                                      placeholder="Enter Link"
                                      className="form-control"
                                      value={element.link || ""}
                                      // onChange={(e) =>
                                      //   this.handleChangeSocial(index, e)
                                      // }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6 col-12">
                                  <div className="form-group">
                                    <label>Social Media</label>
                                    <select
                                      className="form-control"
                                      name="handle"
                                      disabled
                                      value={element.handle || ""}
                                      // onChange={(e) =>
                                      //   this.handleChangeSocial(index, e)
                                      // }
                                    >
                                      <option value={"Please select"}>
                                        Please select
                                      </option>
                                      <option value={"Facebook"}>
                                        Facebook
                                      </option>
                                      <option value={"Instagram"}>
                                        Instagram
                                      </option>
                                      <option value={"LinkedIn"}>
                                        LinkedIn
                                      </option>
                                      <option value={"Medium"}>Medium</option>
                                      <option value={"Reddit"}>Reddit</option>
                                      <option value={"Telegram"}>
                                        Telegram
                                      </option>
                                      <option value={"Twitter"}>Twitter</option>
                                    </select>
                                  </div>
                                </div>
                              </>
                            ))}
                          {/* </div> */}

                          <div className="row mt-2">
                            <div className="col-md-6">
                              <label htmlFor="is_block">Is Blocked</label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    checked={isBlocked == 1}
                                    // onChange={this.handleChange101}
                                    value="1"
                                    name="is_block"
                                    type="radio"
                                    className="form-check-input"
                                    id="is_block"
                                    // disabled
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    checked={isBlocked == 0}
                                    // onChange={this.handleChange101}
                                    value="0"
                                    name="is_block"
                                    id="is_block"
                                    type="radio"
                                    className="form-check-input"
                                    // disabled
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-6">
                              <label htmlFor="is_hide">Is Hidden</label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    checked={isHide == 1}
                                    // onChange={this.handleChange101}
                                    value="1"
                                    // disabled
                                    name="is_hide"
                                    type="radio"
                                    className="form-check-input"
                                    id="is_hide"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    checked={isHide == 0}
                                    // onChange={this.handleChange101}
                                    // disabled
                                    value="0"
                                    name="is_hide"
                                    id="is_hide"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="section-heading d-flex justify-content-between align-items-center">
                      <button
                        className="btn btn-secondary"
                        onClick={checkStatus}
                        // to={"/launchPadEdit/" + params.id}
                      >
                        <h4 className="m-0"> </h4>
                        Edit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default LaunchPadDetails;
