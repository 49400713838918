import React, { useState, useEffect } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from "./baseUrl";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

const baseUrl = myConstList.baseUrl;

const ListingDetails = () => {
  const params = useParams();

  const [tokenName, setTokenName] = useState("");
  const [shortName, setShortName] = useState("");
  const [logo, setLogo] = useState("");
  const [issuePrice, setIssuePrice] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [maxSupply, setMaxSupply] = useState("");
  const [circulatingSupply, setCirculatingSupply] = useState("");
  const [explorer, setExplorer] = useState("");
  const [website, setWebsite] = useState("");
  const [document, setDocument] = useState("");
  const [introduction, setIntroduction] = useState("");

  const [currentStatus, setCurrentStatus] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .post(baseUrl + "/api/getListingById", { id: params.id })
      .then((res) => {
        var resp = res.data.data[0];
        setTokenName(resp.token_name);
        setShortName(resp.short_name);
        setWebsite(resp.website);
        setExplorer(resp.explorer);
        setMaxSupply(resp.max_supply);
        setCirculatingSupply(resp.circulating_supply);
        setIssueDate(resp.issue_date);
        setDocument(resp.document);
        setLogo(resp.logo);
        setIntroduction(resp.introduction);
        setIssuePrice(resp.issue_price);
        setCurrentStatus(resp.request_status);
      });
  };

  const checkStatus = (e) => {
    e.preventDefault();

    var today = new Date();

    // Get the year, month, and day from the date object

    var year = today.getFullYear();
    var month = (today.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-indexed
    var day = today.getDate().toString().padStart(2, "0");

    // Create the formatted date string
    var formattedDate = `${year}-${month}-${day}`;

    if (currentStatus !== "0") {
      toast.dismiss();
      toast.error("Cannot edit if accepted or rejected");
      return false;
    } else {
      window.location.href = "/listingEdit/" + params.id;
    }
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mt-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>

            <h3 className="mt-2 man_hadding mb-3">Listing Details</h3>
            <div className="in_page row">
              <br />

              <div className="col-md-12">
                <form
                  className="update-detail-form"
                  // onSubmit={this.onRoleUpdate}
                  id="add-user"
                >
                  <div className="white_box">
                    <div className="section-heading d-flex justify-content-between align-items-center">
                      <h4 className="m-0"> </h4>

                      <div className="section-heading d-flex justify-content-between align-items-center">
                        {currentStatus !== "2" && (
                          <button
                            className="btn btn-secondary"
                            onClick={checkStatus}
                          >
                            <h4 className="m-0"> </h4>
                            Edit
                          </button>
                        )}

                        <Link className="btn btn-secondary" to="/listing">
                          Back
                        </Link>
                      </div>
                    </div>
                    <div className="tab-content ">
                      <div className="tab-pane active show" id="details">
                        <div className="row">
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Token Name</label>
                              <input
                                value={tokenName}
                                disabled
                                type="text"
                                readOnly={true}
                                className={"form-control"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Short Name</label>
                              <input
                                value={shortName}
                                disabled
                                type="text"
                                className={"form-control"}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Issue Price</label>
                              <input
                                type="number"
                                name="issuePrice"
                                placeholder="Issue Price"
                                className="form-control"
                                value={issuePrice}
                                disabled
                                readOnly={true}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Issue Date</label>
                              <input
                                type="date"
                                name="issueDate"
                                placeholder="Issue Date"
                                className="form-control"
                                disabled
                                readOnly={true}
                                value={issueDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Max Supply</label>
                              <input
                                type="number"
                                name="maxSupply"
                                placeholder="Max Supply"
                                className="form-control"
                                disabled
                                readOnly={true}
                                value={maxSupply}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Circulating Supply</label>
                              <input
                                type="number"
                                name="circulatingSupply"
                                placeholder="Circulating Supply"
                                className="form-control"
                                disabled
                                readOnly={true}
                                value={circulatingSupply}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Explorer Link</label>
                              <input
                                type="text"
                                name="explorer"
                                placeholder="Explorer Link"
                                className="form-control"
                                disabled
                                readOnly={true}
                                value={explorer}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Website Link</label>
                              <input
                                type="text"
                                name="website"
                                placeholder="Website Link"
                                className="form-control"
                                disabled
                                readOnly={true}
                                value={website}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>Logo</label>

                              <img
                                id="file-image"
                                src={baseUrl + "/static/listing/" + logo}
                                className="img-thumbnail"
                                alt="listing_Logo"
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-12">
                            <div className="form-group">
                              <label>White PP Document</label>

                              <a
                                href={baseUrl + "/static/listing/" + document}
                                target="_blank"
                                className="form-control"
                              >
                                {" "}
                                {document}
                              </a>
                            </div>
                          </div>

                          <div className=" col-12">
                            <div className="form-group">
                              <label>Introduction</label>
                              <textarea
                                value={introduction}
                                disabled
                                type="text"
                                className={"form-control"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ListingDetails;
