import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import "react-toastify/dist/ReactToastify.css";

class UserEditRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "this.props.user.id",
      userData: [],
      dataStatus: false,
      portfolioUsdt: 0,
      portfolioInr: 0,
    };
    console.log("this.props", this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.depositeHistory) {
      this.setState({
        userData: nextProps.depositeHistory.data,
        dataStatus: nextProps.depositeHistory.status,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  render() {
    return (
      <div>
        <div
          className="modal fade"
          id="edit-user-modal"
          data-reset="true"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add/Modify Funds</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserEditRecord.propTypes = {
  userFundHistory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(UserEditRecord));
